import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useQueryParams from '../../hooks/useQuery';
import { DefaultURLQuery, ProgressStatus } from '../../types/misc';
import { NOT_FOUND_ROUTE, SUBSCRIPTIONS_ROUTE } from '../../constants';
import ProgressComponent from '../../components/misc/ProgressComponent';
import ContentPanel from '../../components/layouts/ContentPanel';
import SuccessFailureCircle from '../../components/misc/SuccessFailureCircle';
import ExtendedLink from '../../routes/ExtendedLink';
import Button from '../../components/buttons/Button';


export default function OrderPendingPage(): JSX.Element {
  const { payment, lId, sId } = useQueryParams<DefaultURLQuery>();
  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: 'page.orderPending' });

  useEffect((): void => {
    if (!payment || !lId || !sId) {
      navigate(NOT_FOUND_ROUTE);
    }
  }, []);

  return (
    <>
      <ProgressComponent status={ProgressStatus.PENDING} />
      <ContentPanel className="items-center gap-8 pt-16">
        <SuccessFailureCircle status={ProgressStatus.PENDING} />
        <p className="text-[17px] leading-[22px] text-center">{t('description')}</p>
        <ExtendedLink to={SUBSCRIPTIONS_ROUTE}>
          <Button variant="pink">{t('button')}</Button>
        </ExtendedLink>
      </ContentPanel>
    </>
  );
}