import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Language } from '../../types/misc';
import { ApplicationState } from '../../types/redux';

const initialState: ApplicationState = {
  language: Language.NL,
};

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setLanguage(state, action: PayloadAction<Language>): void {
      state.language = action.payload;
    },
  },
});

const applicationReducer = applicationSlice.reducer;

export const { setLanguage } = applicationSlice.actions;
export default applicationReducer;
