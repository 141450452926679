import * as EmailValidator from 'email-validator';
import { UserDetailsInputType } from '../types/userDetails';
import i18n from '../locales/i18n';
import { CardholderInputType } from '../types/cardholders';

export const userDetailsValidators = {
  salutation: (value: string): string => {
    if (!value.length) return `${i18n.t('form.label.salutation')} ${i18n.t('form.error.required')}`;
    return '';
  },
  firstName: (value: string): string => {
    if (!value.length) return `${i18n.t('form.label.firstName')} ${i18n.t('form.error.required')}`;
    return '';
  },
  lastName: (value: string): string => {
    if (!value.length) return `${i18n.t('form.label.lastName')} ${i18n.t('form.error.required')}`;
    return '';
  },
  email: (value: string): string => {
    if (!value.length) return `${i18n.t('form.label.email')} ${i18n.t('form.error.required')}`;
    if (!EmailValidator.validate(value)) return i18n.t('form.error.invalidEmail');
    return '';
  },
  confirmEmail: (value: string, userDetailsEmail: string): string => {
    if (!value.length) return i18n.t('form.error.emailConfirmationRequired');
    if (value !== userDetailsEmail) return i18n.t('form.error.emailMismatch');
    if (!EmailValidator.validate(value)) return i18n.t('form.error.invalidEmail');
    return '';
  },
  zipCode: (value: string): string => {
    if (!value.length) return `${i18n.t('form.label.zipCode')} ${i18n.t('form.error.required')}`;
    return '';
  },
  city: (value: string): string => {
    if (!value.length) return `${i18n.t('form.label.city')} ${i18n.t('form.error.required')}`;
    return '';
  },
  birthdate: (value: Date | null | undefined): string => {
    if (value === undefined) return '';
    if (value === null) return i18n.t('form.error.invalidBirthdate');
    return '';
  },
};

export function validateUserDetails(
  userDetails: UserDetailsInputType,
): Record<keyof UserDetailsInputType, string> {
  const { confirmEmail, email, birthdate } = userDetails;
  return Object.keys(userDetailsValidators).reduce((acc, key): Record<keyof UserDetailsInputType, string> => {
    if (key === 'confirmEmail')
      acc.confirmEmail = userDetailsValidators[key](confirmEmail ?? '', email ?? '');
    else if (key === 'birthdate') acc.birthdate = userDetailsValidators[key](birthdate);
    else
      acc[key as keyof UserDetailsInputType] = userDetailsValidators[
        key as keyof Omit<typeof userDetailsValidators, 'confirmEmail' | 'birthdate'>
      ](userDetails?.[key as keyof Omit<UserDetailsInputType, 'birthdate'>] ?? '');
    return acc;
  }, {} as Record<keyof UserDetailsInputType, string>);
}

export const cardholderValidators = {
  ...userDetailsValidators,
  birthdate: (value: Date | null | undefined): string => {
    if (value === undefined) return `${i18n.t('form.label.birthdate')} ${i18n.t('form.error.required')}`;
    if (value === null) return i18n.t('form.error.invalidBirthdate');
    return '';
  },
};

export function validateCardholderDetails(
  cardholder: CardholderInputType,
): Record<keyof CardholderInputType, string> {
  return Object.keys(cardholderValidators).reduce((acc, key): Record<keyof CardholderInputType, string> => {
    if (key === 'birthdate') acc.birthdate = cardholderValidators[key](cardholder?.birthdate);
    else if (key === 'firstName' || key === 'lastName')
      acc[key as keyof CardholderInputType] = cardholderValidators[key](cardholder?.[key] ?? '');
    return acc;
  }, {} as Record<keyof CardholderInputType, string>);
}
