import React, { useLayoutEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FaCheck, FaHourglass, FaXmark } from 'react-icons/fa6';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {
  CARDHOLDERS_ROUTE,
  PAYMENT_ROUTE,
  SHOP_ROUTES,
  SUBSCRIPTIONS_ROUTE,
  USER_DETAILS_ROUTE,
} from '../../constants';
import ExtendedLink from '../../routes/ExtendedLink';
import { ProgressStatus } from '../../types/misc';

interface ProgressComponentProps {
  status?: ProgressStatus;
}

export default function ProgressComponent({ status }: ProgressComponentProps): JSX.Element {
  return status !== undefined ? (
    <SuccessFailureProgressComponent status={status} />
  ) : (
    <ShopProgressComponent />
  );
}

function ShopProgressComponent(): JSX.Element {
  const { pathname } = useLocation();
  const { t } = useTranslation('translation', { keyPrefix: 'component.shopProgress' });

  const containerRef = useRef<HTMLDivElement>(null);
  const stepRefs = useRef<HTMLDivElement[]>([]);
  const [progressWidth, setProgressWidth] = useState(0);

  const progress = SHOP_ROUTES.indexOf(pathname);

  const updateProgressWidth = (): void => {
    if (stepRefs.current.length && progress >= 0 && containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const progressStepRect = stepRefs.current[progress]?.getBoundingClientRect();

      setProgressWidth(progressStepRect.right - containerRect.left + 20);
    }
  };

  useLayoutEffect((): (() => void) => {
    updateProgressWidth();
    window.addEventListener('resize', updateProgressWidth);

    return (): void => {
      window.removeEventListener('resize', updateProgressWidth);
    };
  }, [progress]);

  return (
    <div
      ref={containerRef}
      className="relative overflow-hidden w-full h-[60px] rounded-sb-lg flex bg-white items-center justify-between px-5 py-4">
      <div
        className="absolute left-0 top-0 h-[60px] transition-all duration-300 rounded-sb-lg bg-sb-light-blue"
        style={{ width: progressWidth }}
      />
      {SHOP_ROUTES.map((s, i): JSX.Element => (
        <ExtendedLink
          key={i}
          to={SHOP_ROUTES[i]}
          className={classNames('z-[1]', { 'disabled-link': i > progress })}>
          <div
            ref={(el): void => {
              if (el) stepRefs.current[i] = el;
            }}
            className="flex items-center gap-2 ">
            <div
              className={classNames(
                'flex-shrink-0 transition-none w-[30px] h-[30px] rounded-full flex items-center justify-center text-[15px] leading-[21px]',
                {
                  'bg-sb-purple': i <= progress,
                },
              )}>
              {i < progress ? <FaCheck className="w-3 h-3" /> : i + 1}
            </div>
            {progress === i && t(ROUTE_NAMES[s as keyof typeof ROUTE_NAMES]).toLowerCase()}
          </div>
        </ExtendedLink>
      ))}
    </div>
  );
}

const ROUTE_NAMES = {
  [SUBSCRIPTIONS_ROUTE]: 'subscriptions',
  [USER_DETAILS_ROUTE]: 'userDetails',
  [CARDHOLDERS_ROUTE]: 'cardholders',
  [PAYMENT_ROUTE]: 'payment',
};

function SuccessFailureProgressComponent({
  status,
}: Required<ProgressComponentProps>): JSX.Element {
  const { t } = useTranslation('translation', { keyPrefix: 'general' });

  return (
    <div className="relative w-full h-[60px] rounded-sb-lg flex bg-white items-center justify-between px-5 py-4">
      <div
        className={`absolute w-full top-0 left-0 h-[60px] rounded-sb-lg ${
          status === ProgressStatus.SUCCESS ? 'bg-sb-light-green' 
          : status === ProgressStatus.PENDING ? 'bg-gray-100'
          : 'bg-sb-light-red'
        }`}
      />
      {SHOP_ROUTES.map((_, i): JSX.Element => (
        <div key={i} className="flex items-center gap-2 z-[1]">
          <div
            key={i}
            className={`flex-shrink-0 transition-none w-[30px] h-[30px] rounded-full flex items-center justify-center ${
              status === ProgressStatus.SUCCESS ? 'bg-sb-green' 
              : status === ProgressStatus.PENDING ? 'bg-gray-400'
              : 'bg-sb-red'
            }`}>
            {status === ProgressStatus.SUCCESS || i < SHOP_ROUTES.length - 1 ? (
              <FaCheck className="w-3 h-3" />
            ) : status === ProgressStatus.PENDING ? (
              <FaHourglass className="w-3 h-3" />
            ) : (
              <FaXmark className="w-3 h-3" />
            )}
          </div>
          {i === SHOP_ROUTES.length - 1 && (status === ProgressStatus.SUCCESS ? t('paid') : status === ProgressStatus.PENDING? t('pending') : t('failure'))}
        </div>
      ))}
    </div>
  );
}
