import React, { InputHTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

type MinimalisticNumberInputProps = {
  className?: string;
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'className'>;

export default function MinimalisticNumberInput({
  className,
  ...props
}: MinimalisticNumberInputProps): JSX.Element {
  const inputRef = React.useRef<HTMLInputElement>(null);

  return (
    <input
      {...props}
      type="number"
      ref={inputRef}
      onWheel={(): void => {
        inputRef.current?.blur();
      }}
      onKeyDown={(ev): void => {
        if (ev.key === 'Enter') {
          inputRef.current?.blur();
        }
      }}
      className={twMerge('p-1 text-sm h-fit text-center m-0 rounded-sb-lg no-spin-buttons border-0', className)}
    />
  );
}
