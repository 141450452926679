import { format, toZonedTime } from 'date-fns-tz';
import { nl, enUS } from 'date-fns/locale';
import useLanguage from './selectors/useLanguage';
import useShop from './selectors/useShop';

export default function useDateFormat(): typeof format {
  const lang = useLanguage();
  const { timeZone } = useShop();

  return (date, formatString, options = {}): string => {
    const zonedDate = toZonedTime(date, timeZone);
    return format(zonedDate, formatString, {
      locale: lang === 'nl' ? nl : enUS,
      timeZone,
      ...options,
    }).toLowerCase();
  };
}
