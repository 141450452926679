import { createTransform } from 'redux-persist';
import { UserDetailsState, CardholdersState, ShopState } from '../types/redux';
import { SubscriptionType } from '../types/subscription';
import { CardholderInputType } from '../types/cardholders';

export const shopTransform = createTransform(
  (incoming: ShopState): ShopState => incoming,
  (outgoing: ShopState): ShopState => {
    const { subscriptionTypes } = outgoing;
    if (!subscriptionTypes) return outgoing;
    return {
      ...outgoing,
      subscriptionTypes: subscriptionTypes.map((st): SubscriptionType => {
        const { sellingFrom, sellingTo, capacityGroup } = st;
        const { startDate, endDate } = capacityGroup;
        return {
          ...st,
          sellingFrom: new Date(sellingFrom),
          sellingTo: new Date(sellingTo),
          capacityGroup: {
            ...capacityGroup,
            startDate: new Date(startDate),
            endDate: new Date(endDate),
          },
        };
      }),
    };
  },
  { whitelist: ['shop'] },
);

export const userDetailsTransform = createTransform(
  (incoming: UserDetailsState): UserDetailsState => incoming,
  (outgoing: UserDetailsState): UserDetailsState => {
    const { userDetails } = outgoing;
    const { birthdate } = userDetails || {};
    if (!userDetails || !birthdate) return outgoing;
    return {
      ...outgoing,
      userDetails: {
        ...userDetails,
        birthdate: new Date(birthdate),
      },
    };
  },
  { whitelist: ['userDetails'] },
);

export const cardholdersTransform = createTransform(
  (incoming: CardholdersState): CardholdersState => incoming,
  (outgoing: CardholdersState): CardholdersState => {
    const { cardholders } = outgoing;
    if (!cardholders) return outgoing;
    return {
      ...outgoing,
      cardholders: Object.fromEntries(
        Object.entries(cardholders).map(([key, cs]): [string, CardholderInputType[]] => [
          key,
          cs.map((c): CardholderInputType => {
            const { birthdate } = c;
            if (!birthdate) return c;
            return { ...c, birthdate: new Date(birthdate) };
          }),
        ]),
      ),
    };
  },
  { whitelist: ['cardholders'] },
);
