import React, { useState } from 'react';
import { FaChevronRight } from 'react-icons/fa6';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { toast } from 'react-toastify';
import TextInput from './TextInput';
import IconButton from '../buttons/IconButton';
import useShoppingCart from '../../hooks/selectors/useShoppingCart';
import useCrashHandler from '../../hooks/useCrashHandler';
import { cartService } from '../../services';

interface DiscountInputProps {
  className?: string;
}

export default function DiscountInput({ className }: DiscountInputProps): JSX.Element {
  const { discounts } = useShoppingCart();
  const crashHandler = useCrashHandler();
  const { t } = useTranslation('translation', { keyPrefix: 'component.discountInput' });
  const [code, setCode] = useState<string>('');

  const submitCode = (): void => {
    if (discounts?.some((d): boolean => d.code === code)) {
      toast.error(t("error.code_applied"));
    }
    cartService.addDiscountCode(code).catch(crashHandler);
  };

  return (
    <div className={twMerge('relative flex w-full', className)}>
      <TextInput
        text={code}
        setText={setCode}
        className="!rounded-full w-full pr-14"
        placeholder={t('code')}
      />
      <IconButton
        onClick={submitCode}
        className="bg-black h-[60px] w-[60px] absolute right-0 text-white"
        iconClassName="text-white"
        disabled={!code}
        icon={FaChevronRight}
      />
    </div>
  );
}
