import React from 'react';
import { FaCheck, FaXmark } from 'react-icons/fa6';
import { twMerge } from 'tailwind-merge';
import useShoppingCart from '../../hooks/selectors/useShoppingCart';
import IconButton from '../buttons/IconButton';
import useCrashHandler from '../../hooks/useCrashHandler';
import { cartService } from '../../services';

interface DiscountListProps {
  className?: string;
}

export default function DiscountList({ className }: DiscountListProps): JSX.Element {
  const { discounts } = useShoppingCart();
  const crashHandler = useCrashHandler();

  const removeDiscount = (code: string): void => {
    cartService.removeDiscountCode(code).catch(crashHandler);
  };

  return (
    <div className={twMerge('flex flex-col gap-2', className)}>
      {discounts?.map((d): JSX.Element => (
        <div key={d.code} className="flex items-center gap-2 ">
          <IconButton
            className="h-fit w-fit"
            iconClassName="w-5 h-5"
            onClick={(): void => removeDiscount(d.code)}
            icon={FaXmark}
          />
          <p className={`${
            d.times > 0 ? 
            'opacity-100' :
            'opacity-50 line-through'
            }`}>{d.code}</p>
          {d.times > 0 && <FaCheck className='text-green-500'/>}
        </div>
      ))}
    </div>
  );
}
