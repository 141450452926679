import React, { HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

type MessageBubbleProps = HTMLAttributes<HTMLDivElement>;

export default function MessageBubble({ className, ...props }: MessageBubbleProps): JSX.Element {
  return (
    <div
      {...props}
      className={twMerge(
        'bg-sb-green text-[15px] leading-[21px] px-5 py-4 flex items-center justify-center rounded-sb-md',
        className,
      )}
    />
  );
}
