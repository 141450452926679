export enum Language {
  NL = 'nl',
  EN = 'en',
}

export interface DefaultURLQuery {
  lId?: number;
  sId?: number;
  shopId?: number;
  payment?: string;
  paymentId?: number;
}

export type Price = {
  price: number;
  discountedPrice?: number;
};

export enum EventType {
  SUBMIT_USER_DETAILS = 'SUBMIT_USER_DETAILS',
  SUBMIT_CARDHOLDERS = 'SUBMIT_CARDHOLDERS',
  SUBMIT_PAYMENT = 'SUBMIT_PAYMENT',
}

/**
 * The payment status of a payment.
 * Categories: paid, failed, pending.
 */
export enum ProgressStatus {
  SUCCESS = 'success',
  FAILURE = 'failure',
  PENDING = 'pending',
}