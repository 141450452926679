export const resizeImage = (file: File, maxSize: number): Promise<File> => {
    return new Promise((resolve, reject): void => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event: ProgressEvent<FileReader>): void => {
        const img = new Image();
        img.src = event.target!.result as string;
  
        img.onload = (): void => {
          const { width, height } = img;
  
          let newWidth;
          let newHeight;
  
          if (width > height) {
            newWidth = maxSize;
            newHeight = (maxSize * height) / width;
          } else {
            newHeight = maxSize;
            newWidth = (maxSize * width) / height;
          }
  
          const canvas = document.createElement('canvas');
          canvas.width = newWidth;
          canvas.height = newHeight;
  
          const ctx = canvas.getContext('2d');
          ctx!.drawImage(img, 0, 0, newWidth, newHeight);
  
          canvas.toBlob(
            (blob): void => {
              if (!blob) {
                reject(new Error('Failed to create blob.'));
                return;
              }
  
              const resizedFile = new File([blob], file.name, { type: file.type });
              resolve(resizedFile);
            },
            file.type,
            0.5,
          );
        };
  
        img.onerror = (): void => {
          reject(new Error('Failed to load image.'));
        };
      };
  
      reader.onerror = (): void => {
        reject(new Error('Failed to read file.'));
      };
    });
  };