import React from 'react';
import { twMerge } from 'tailwind-merge';

interface LabeledProps {
  className?: string;
  labelClassName?: string;
  label: string;
  children: React.ReactNode;
}

export default function Labeled({
  className,
  label,
  labelClassName,
  children,
}: LabeledProps): JSX.Element {
  return (
    <div className={twMerge('flex flex-col gap-2', className)}>
      <label className={twMerge('ml-2.5 text-[17px] leading-[22px]', labelClassName)}>
        {label}
      </label>
      {children}
    </div>
  );
}
