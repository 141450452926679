import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ContentPanel from '../components/layouts/ContentPanel';
import { subscriptionShopService } from '../services';
import { SUBSCRIPTIONS_ROUTE } from '../constants';

export default function NotFoundPage(): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: 'page.notFound' });

  useEffect((): void => {
    subscriptionShopService.checkIfShopIsAvailable(1, 1)
      .then((isAvailable): void => {
        if (isAvailable) {
          navigate(`${SUBSCRIPTIONS_ROUTE}?lId=1&sId=1`);
        }
      })
  }, []);

  return (
    <ContentPanel className='lg:mt-[112px]'>
      <h1>{t('title')}</h1>
      <p>{t('description')}</p>
    </ContentPanel>
  );
}
