import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ShopState } from '../../types/redux';
import { SubscriptionShop, SubscriptionType } from '../../types/subscription';

const initialState: ShopState = { name: '', timeZone: '', bookingFee: 0, subscriptionTypes: [], locationName: '' };

const shopSlice = createSlice({
  name: 'shop',
  initialState,
  reducers: {
    setSubscriptionTypes: (state, action: PayloadAction<SubscriptionType[]>): void => {
      state.subscriptionTypes = action.payload;
    },
    setShop(state, action: PayloadAction<SubscriptionShop>): void {
      state.name = action.payload.name;
      state.bookingFee = action.payload.bookingFee;
      state.timeZone = action.payload.location.timezone;
      state.locationName = action.payload.location.name;
    },
  },
});

const shopReducer = shopSlice.reducer;

export const { setSubscriptionTypes, setShop } = shopSlice.actions;
export default shopReducer;
