import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 } from 'uuid';
import { CardholdersState } from '../../types/redux';
import { CardholderInputType } from '../../types/cardholders';

const initialState: CardholdersState = { pictures: {}, cardholders: {} };

const cardholdersSlice = createSlice({
  name: 'cardholders',
  initialState,
  reducers: {
    initCardholders(state, action: PayloadAction<{ purchaseId: string; amount: number }>): void {
      state.cardholders[action.payload.purchaseId] = Array.from({
        length: action.payload.amount,
      }).map((): { id: string } => ({ id: v4() }));
    },
    deleteCardholders(state, action: PayloadAction<string>): void {
      const pictureIds = state.cardholders[action.payload]?.map(({ pictureId }): number | undefined => pictureId) ?? [];
      state.cardholders = Object.fromEntries(
        Object.entries(state.cardholders).filter(([key]): boolean => key !== action.payload),
      );
      state.pictures = Object.fromEntries(
        Object.entries(state.pictures).filter(([key]): boolean => !pictureIds.includes(+key)),
      );
    },
    editCardholder(
      state,
      action: PayloadAction<{ purchaseId: string; cardholder: Partial<CardholderInputType> }>,
    ): void {
      const { purchaseId } = action.payload;
      if (!state.cardholders[purchaseId]) {
        state.cardholders[purchaseId] = [];
      }
      const index = state.cardholders[purchaseId].findIndex(
        ({ id }): boolean => id === action.payload.cardholder.id,
      );
      if (index === -1) {
        throw new Error('Cardholder not found');
      } else {
        state.cardholders[purchaseId][index] = {
          ...state.cardholders[purchaseId][index],
          ...action.payload.cardholder,
        };
      }
    },
    addPicture(state, action: PayloadAction<{ pictureId: number; base64Img: string }>): void {
      state.pictures[action.payload.pictureId] = action.payload.base64Img;
    },
    removePicture(state, action: PayloadAction<number>): void {
      delete state.pictures[action.payload];
    },
  },
});

const cardHoldersReducer = cardholdersSlice.reducer;

export const { initCardholders, deleteCardholders, editCardholder, addPicture, removePicture } =
  cardholdersSlice.actions;
export default cardHoldersReducer;
