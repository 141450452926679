import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProgressStatus } from '../../types/misc';

interface SuccessFailureCircleProps {
  status: ProgressStatus;
}

export default function SuccessFailureCircle({ status }: SuccessFailureCircleProps): JSX.Element {
  const { t } = useTranslation('translation', { keyPrefix: 'general' });
  return (
    <div
      className={`rounded-full animate-grow w-[170px] h-[170px] flex justify-center items-center text-[32px] leading-[25px] font-ginto-bold ${
        status === ProgressStatus.SUCCESS ? 'bg-sb-green' : status === ProgressStatus.PENDING ? 'bg-gray-400' : 'bg-sb-red'
      }`}>
      {status === ProgressStatus.SUCCESS ? t('success') : status === ProgressStatus.PENDING ? t('pending') : t('failure')}
    </div>
  );
}
