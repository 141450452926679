import { addPicture, editCardholder, removePicture } from '../redux/slices/cardholdersSlice';
import { store } from '../redux/store';
import { UploadPictureResponse } from '../types/cardholders';
import { SubscriptionCardholderIdentityDto } from '../types/subscription';
import httpService from './httpService';

/**
 * Upload a picture for a cardholder and set his state in the store
 *
 * @param purchaseId id of the purchase
 * @param cardholderId id of the cardholder
 * @param picture picture to upload
 * @returns Promise that resolves when the picture is uploaded
 */
export async function uploadPicture(
  purchaseId: string,
  cardholderId: string,
  picture: File,
): Promise<string> {
  const formData = new FormData();
  formData.append('picture', picture);
  return httpService.post<UploadPictureResponse>('/pictures', formData).then(({ data }): Promise<string> => {
    return new Promise<string>((resolve, reject): void => {
      const reader = new FileReader();
      reader.readAsDataURL(picture);

      reader.onload = (): void => {
        const base64 = reader.result as string;
        store.dispatch(
          editCardholder({ purchaseId, cardholder: { id: cardholderId, pictureId: data.id } }),
        );
        store.dispatch(addPicture({ pictureId: data.id, base64Img: base64 }));

        resolve(base64);
      };

      reader.onerror = (error): void => {
        reject(error);
      };
    });
  });
}

/**
 * Delete the picture of a cardholder
 *
 * @param purchaseId id of the purchase
 * @param cardholderId id of the cardholder
 */
export function deletePicture(purchaseId: string, cardholderId: string): Promise<void> {
  const cardholder = store
    .getState()
    .cardholders.cardholders[purchaseId]?.find((c): boolean => c.id === cardholderId);
  if (!cardholder) return Promise.reject(new Error('Cardholder not found'));
  if (!cardholder.pictureId) return Promise.resolve();

  store.dispatch(
    editCardholder({ purchaseId, cardholder: { id: cardholderId, pictureId: undefined } }),
  );
  store.dispatch(removePicture(cardholder.pictureId));
  return Promise.resolve();
}

export function getCardholders(purchaseId: string): SubscriptionCardholderIdentityDto[] {
  const { cardholders } = store.getState().cardholders;
  if (!cardholders[purchaseId]) throw new Error('Cardholders not found');
  return cardholders[purchaseId].map(({ firstName, lastName, pictureId, birthdate }): SubscriptionCardholderIdentityDto => {
    if (!firstName || !lastName || !birthdate) throw new Error('Cardholder incomplete');
    return { firstName, lastName, pictureId, birthdate };
  });
}
