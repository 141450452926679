import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PiArrowUUpLeftBold } from 'react-icons/pi';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import Logo from '../misc/Logo';
import { Language } from '../../types/misc';
import Button from '../buttons/Button';
import useLanguage from '../../hooks/selectors/useLanguage';
import { setLanguage } from '../../redux/slices/applicationSlice';
import useMediaQuery from '../../hooks/useMediaQuery';
import ExtendedLink from '../../routes/ExtendedLink';
import { SUBSCRIPTIONS_ROUTE, WEB_PAGE_URL } from '../../constants';

interface DefaultLayoutProps {
  children?: React.ReactNode;
}
export default function DefaultLayout({ children }: DefaultLayoutProps): JSX.Element {
  const lang = useLanguage();
  const { t } = useTranslation('translation', { keyPrefix: 'component.defaultLayout' });
  const dispatch = useDispatch();
  const mediaQuery = useMediaQuery();
  const location = useLocation();

  return (
    <>
      <div className="h-full flex-1 flex flex-col z-50 bg-sb-dark-purple">
        <div className="flex items-center lg:fixed pointer-events-none lg:top-0 lg:max-w-full justify-between py-5 max-w-2xl mx-auto px-5 w-full">
          <Logo className="max-h-[30px] lg:max-h-[55px] pointer-events-auto" text={mediaQuery(1024)} />
          <div className="flex gap-4 items-center relative pointer-events-auto">
            <div className="h-[60px] w-[120px] flex transition-all bg-black rounded-sb-lg px-5 gap-2.5 absolute left-0 transform -translate-x-[85px]">
              {Object.values(Language).map((l): JSX.Element => (
                <button
                  key={l}
                  type="button"
                  onClick={(): void => { dispatch(setLanguage(l)); }}
                  className={classNames('text-white p-0', { 'opacity-30': lang !== l })}>
                  {l.toUpperCase()}
                </button>
              ))}
            </div>
            { location.pathname === SUBSCRIPTIONS_ROUTE ?
            (
              <a href={WEB_PAGE_URL} className="relative z-10">
                <Button variant="white" className="gap-1">
                  <PiArrowUUpLeftBold className="h-6 w-6" />
                  {t('toHome')}
                </Button>
              </a>
            ) : (
              <ExtendedLink to="/" className="relative z-10">
                <Button variant="white" className="gap-1">
                  <PiArrowUUpLeftBold className="h-6 w-6" />
                  {t('toHome')}
                </Button>
              </ExtendedLink>
            )}
          </div>
        </div>
        <div className="flex flex-1 flex-col px-5 pb-10 gap-4 lg:gap-8 max-w-2xl lg:max-w-6xl mx-auto w-full">
          {children || <Outlet />}
        </div>
      </div>
    </>
  );
}
