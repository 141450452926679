import React, { HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

type ContentPanelProps = HTMLAttributes<HTMLDivElement>;

export default function ContentPanel({ className, ...props }: ContentPanelProps): JSX.Element {
  return (
    <div
      className={twMerge('rounded-sb-lg transition-all bg-white px-4 py-5 h-fit w-full flex flex-col gap-4', className)}
      {...props}
    />
  );
}
