import { useSearchParams } from 'react-router-dom';

export default function useQueryParams<T extends object>(): T {
  const [searchParams] = useSearchParams();
  const params = {};

  searchParams.forEach((value, key): void => {
    (params as any)[key] = value;
  });

  return params as T;
}
