import { setShop, setSubscriptionTypes } from '../redux/slices/shopSlice';
import { store } from '../redux/store';
import { SubscriptionShop, SubscriptionType } from '../types/subscription';
import httpService from './httpService';

/**
 * Gets the subscription types
 * @returns {SubscriptionType[]} Promise that resolves to an array of subscription types
 */
export async function getSubscriptionTypes(): Promise<SubscriptionType[]> {
  return httpService
    .get<SubscriptionType[]>('/locations/:locationId/subscription-shops/:subscriptionShopId/subscription-types')
    .then(({ data }): SubscriptionType[] => {
      store.dispatch(setSubscriptionTypes(data));
      return data;
    });
}

/**
 * Gets a subscription type by id
 * 
 * @param id The id of the subscription type
 * @returns {SubscriptionType} The subscription type
 */
export function getSubscriptionTypeById(id: number): SubscriptionType {
  const subscription = store.getState().shop.subscriptionTypes.find((s): boolean => s.id === id);
  if (!subscription) {
    throw new Error(`Subscription with id ${id} not found`);
  }
  return subscription;
}

/**
 * Gets a name and bookingfee of a shop
 * 
 * @returns {SubscriptionShop} Promise that resolves to a shop
 */
export async function getSubscriptionShop(): Promise<SubscriptionShop> {
  return httpService
    .get<SubscriptionShop>('/locations/:locationId/subscription-shops/:subscriptionShopId')
    .then(({ data }): SubscriptionShop => {
      store.dispatch(setShop(data));
      return data;
    });
}

/**
 * Checks if a shop is available
 * 
 * @param locationId The id of the location
 * @param subscriptionShopId The id of the subscription shop
 * @returns {Promise<boolean>} Promise that resolves to a boolean, true if the shop is available, false otherwise
 */
export async function checkIfShopIsAvailable(locationId: number, subscriptionShopId: number): Promise<boolean> {
  return httpService
    .get(`/locations/${locationId}/subscription-shops/${subscriptionShopId}/available`)
    .then((): boolean => true)
    .catch((): boolean => false);
}
