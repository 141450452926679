import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaSpinner } from 'react-icons/fa6';
import { DefaultURLQuery } from '../../types/misc';
import useQueryParams from '../../hooks/useQuery';
import { paymentService } from '../../services';
import ContentPanel from '../../components/layouts/ContentPanel';
import { ORDER_FAILURE_ROUTE, ORDER_PENDING_ROUTE, ORDER_SUCCESS_ROUTE } from '../../constants';
import useCrashHandler from '../../hooks/useCrashHandler';

export default function ProcessPostPaymentPage(): JSX.Element {
  const crashHandler = useCrashHandler();
  const { shopId, lId, payment } = useQueryParams<DefaultURLQuery>();
  const { t } = useTranslation('translation', { keyPrefix: 'page.processPostPayment' });
  const navigate = useNavigate();

  const params = `?sId=${shopId}&lId=${lId}&payment=${payment}`;

  useEffect((): void | (() => void) => {
    const timeout = setTimeout(async (): Promise<void> => {
      if (!payment) {
        navigate(`/payment${params}`);
        return;
      }
      const data = await paymentService.checkPaymentStatus(payment).catch(crashHandler);
      navigate(
        `${(data === 'paid' || data === 'authorized') ? ORDER_SUCCESS_ROUTE 
        : (data === 'pending' || data === 'open') ? ORDER_PENDING_ROUTE
        : ORDER_FAILURE_ROUTE}${params}`
      );
    }, 5000);

    return (): void => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <ContentPanel className="items-center gap-8 pt-16 lg:mt-[112px]">
      <FaSpinner className="animate-spin w-[120px] h-[120px] text-sb-pink" />
      <p className="text-center text-lg font-ginto-bold">{t('description')}</p>
    </ContentPanel>
  );
}
