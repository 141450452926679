import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import useQuery from './useQuery';
import { DefaultURLQuery } from '../types/misc';
import { NOT_FOUND_ROUTE, SUBSCRIPTIONS_ROUTE, USER_DETAILS_ROUTE } from '../constants';

export default function useCrashHandler(): (err: Error) => void {
  const navigate = useNavigate();
  const query = useQuery<DefaultURLQuery>();
  const { t } = useTranslation('translation', { keyPrefix: 'error' });
  return (err: Error): void => {
    switch (err.message) {
      case 'Network Error':
        toast.error(t('network_error'));
        break;
      case 'REPAIR_FAILED':
        toast.error(t('handle_crash'));
        navigate(`${SUBSCRIPTIONS_ROUTE}?lId=${query.lId}&sId=${query.sId}`);
        break;
      case 'USER_DETAILS_MISSING':
        toast.error(t('user_details_missing'));
        console.error(err);
        navigate(`${USER_DETAILS_ROUTE}?lId=${query.lId}&sId=${query.sId}`);
        break;
      case 'SHOPPING_CART_EMPTY':
        toast.error(t('shopping_cart_empty'));
        navigate(`${SUBSCRIPTIONS_ROUTE}?lId=${query.lId}&sId=${query.sId}`);
        break;
      case 'PRICES_CHANGED':
        toast.warning(t('prices_changed'));
        break;
      case 'SOMETHING_BIG_CHANGED':
        toast.error(t('big_change'));
        navigate(`${SUBSCRIPTIONS_ROUTE}?lId=${query.lId}&sId=${query.sId}`);
        break;
      case 'MISSING_QUERY_PARAMS':
        navigate(`${NOT_FOUND_ROUTE}`);
        break;
      default:
        toast.error(t('went_wrong'));
        console.error(err);
    }
  };
}
