// link that preserves query parameters

import React, { ComponentProps } from 'react';
import { Link } from 'react-router-dom';
import useQuery from '../hooks/useQuery';

type ExtendedLinkProps = { to: string } & ComponentProps<typeof Link>;

export default function ExtendedLink({ to, ...props }: ExtendedLinkProps): JSX.Element {
  const query = useQuery();

  const params: string = Object.keys(query)
    .map((key): string => `${key}=${query[key as keyof typeof query]}`)
    .join('&');

  return <Link to={`${to}?${params}`} {...props} />;
}
