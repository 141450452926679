import React, { useRef } from 'react';
import { FaUserLarge, FaXmark } from 'react-icons/fa6';
import { FaPencilAlt } from 'react-icons/fa';
import { twMerge } from 'tailwind-merge';
import { resizeImage } from '../../utils/convertUtils';
import IconButton from '../buttons/IconButton';

interface PictureInputProps {
  image: string | undefined;
  setImage: (image: string | undefined) => void;
  className?: string;
}

const MAX_IMAGE_SIZE = 1024;

export default function PictureInput({
  image,
  setImage,
  className,
}: PictureInputProps): JSX.Element {
  const inputFile = useRef<HTMLInputElement | null>(null);

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const file = e.target.files![0];
    if (!file) {
      return;
    }
    const resizedImage = await resizeImage(file, MAX_IMAGE_SIZE);

    setImage(window.URL.createObjectURL(resizedImage));
  };

  return (
    <>
      {image ? (
        <div className="w-fit h-fit relative flex-shrink-0">
          <img
            alt="upload"
            src={image}
            className={twMerge('w-[100px] h-[100px] object-cover rounded-sb-md', className)}
          />
          <IconButton
            onClick={(): void => inputFile.current?.click()}
            icon={FaPencilAlt}
            className="bg-black absolute -top-2 -right-2 text-white hover:bg-opacity-100 hover:bg-[#0f172a]"
          />
          <IconButton
            onClick={(): void => setImage(undefined)}
            icon={FaXmark}
            className="bg-black absolute -bottom-2 -right-2 text-white hover:bg-opacity-100 hover:bg-[#0f172a]"
          />
        </div>
      ) : (
        <div
          className="w-[100px] h-[100px] flex-shrink-0 bg-black rounded-sb-md pt-5 cursor-pointer"
          onClick={(): void => inputFile.current?.click()}>
          <FaUserLarge className={twMerge('w-full h-full text-white', className)} />
        </div>
      )}

      <input
        type="file"
        className="hidden"
        onChange={handleUpload}
        accept="image/*"
        ref={inputFile}
      />
    </>
  );
}
