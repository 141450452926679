import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ShoppingCartState } from '../../types/redux';
import { UsedDiscountCode } from '../../types/discount';
import { DiscountedSubscriptionOrderDto } from '../../types/payment';
import { SubscriptionPurchaseDto } from '../../types/subscription';

const initialState: ShoppingCartState = {
  purchases: [],
  discounts: [],
  price: { totalPrice: { price: 0 }, bookingFee: { price: 0 } },
};

const shoppingCartSlice = createSlice({
  name: 'shoppingCart',
  initialState,
  reducers: {
    /**
     * Adds a discount to the shopping cart
     * @param state Current state of the shopping cart
     * @param action Action made to the shopping cart. Contains the discount to add
     */
    addDiscount(state, action: PayloadAction<UsedDiscountCode>): void {
      if (!state.discounts.find((discount): boolean => discount.code === action.payload.code)) {
        state.discounts.push(action.payload);
      }
    },

    /**
     * Removes a discount from the shopping cart
     * @param state Current state of the shopping cart
     * @param action Action made to the shopping cart. Contains the code of the discount to remove
     */
    removeDiscount(state, action: PayloadAction<string>): void {
      state.discounts = state.discounts.filter((discount): boolean => discount.code !== action.payload);
    },

    /**
     * Sets the booking fee of the shopping cart
     * @param state Current state of the shopping cart
     * @param action Action made to the shopping cart. Contains the new booking fee
     */
    setBookingFee(state, action: PayloadAction<number>): void {
      state.price.bookingFee.price = action.payload;
    },

    /**
     * Sets the shopping cart to the given cart
     * @param state Current state of the shopping cart
     * @param action Action made to the shopping cart. Contains the new cart as a DiscountedSubscriptionOrderDto
     */
    setCart(state, action: PayloadAction<DiscountedSubscriptionOrderDto>): void {
      const { purchases, usedCodes, total, discountedTotal, bookingFee, discountedBookingFee } =
        action.payload;
      state.discounts = [
        ...usedCodes,
        ...state.discounts
          .filter(
            (discount): boolean =>
              !action.payload.usedCodes
                .map((code: UsedDiscountCode): string => code.code)
                .includes(discount.code),
          )
          .map((discount): { code: string, times: number } => ({ code: discount.code, times: 0 })),
      ];
      state.purchases = purchases;
      state.price = {
        totalPrice: { price: total, discountedPrice: discountedTotal },
        bookingFee: { price: bookingFee, discountedPrice: discountedBookingFee },
      };
    },
    createBackup(state): void {
      state.backup = { ...state, backup: undefined };
    },
    restoreCart(state): void {
      if (state.backup) {
        state.purchases = state.backup.purchases;
        state.discounts = state.backup.discounts;
        state.price = state.backup.price;
        state.backup = undefined;
      }
    },
    deleteBackup(state): void {
      state.backup = undefined;
    },

    removeItems(state, action: PayloadAction<string[]>): void {
      state.purchases = state.purchases.filter(
        (purchase): boolean => !action.payload.includes(purchase.purchaseId),
      );
    },

    addItems(state, action: PayloadAction<SubscriptionPurchaseDto[]>): void {
      const newPurchases = [...state.purchases, ...action.payload];
      newPurchases.sort((a, b): number => a.subscriptionTypeId - b.subscriptionTypeId);
      state.purchases = newPurchases;
    },

    setPurchases(state, action: PayloadAction<SubscriptionPurchaseDto[]>): void {
      state.purchases = action.payload;
    },

    clearCart(state): void {
      state.purchases = [];
      state.discounts = [];
      state.price = { totalPrice: { price: 0 }, bookingFee: { price: 0 } };
    },
  },
});

const shoppingCartReducer = shoppingCartSlice.reducer;

export const {
  addDiscount,
  removeDiscount,
  setBookingFee,
  setCart,
  createBackup,
  restoreCart,
  deleteBackup,
  addItems,
  removeItems,
  setPurchases,
  clearCart,
} = shoppingCartSlice.actions;

export default shoppingCartReducer;
