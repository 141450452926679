import { StatusCodes } from 'http-status-codes';
import _ from 'lodash';
import { v4 } from 'uuid';
import {
  addDiscount,
  removeDiscount,
  setCart,
  removeItems,
  addItems,
  restoreCart,
  deleteBackup,
  setPurchases,
  createBackup,
  clearCart,
} from '../redux/slices/shoppingCartSlice';
import { store } from '../redux/store';
import {
  DiscountableSubscriptionOrderDto,
  DiscountedSubscriptionOrderDto,
} from '../types/discount';
import { SubscriptionPurchaseDto } from '../types/subscription';
import httpService from './httpService';
import { getSubscriptionTypeById } from './subscriptionShopService';
import { deleteCardholders, initCardholders } from '../redux/slices/cardholdersSlice';
// eslint-disable-next-line import/no-cycle
import { subscriptionShopService } from '.';
import { setLanguage } from '../redux/slices/applicationSlice';
import { notFalsy } from '../utils/typeUtils';

/**
 * Adds an array of items to the cart
 *
 * @param items The items to add
 * @returns {Promise<DiscountedSubscriptionOrderDto>} The updated cart
 */
export async function addItemsToCart(items: SubscriptionPurchaseDto[]): Promise<void> {
  store.dispatch(createBackup());
  store.dispatch(addItems(items));
  return updateCart()
    .then((res): void => {
      items.forEach(({ purchaseId, subscriptionTypeId }): void => {
        const { cardholdersCount } = getSubscriptionTypeById(subscriptionTypeId);
        store.dispatch(initCardholders({ purchaseId, amount: cardholdersCount }));
      });
      return res;
    })
}

/**
 * Removes a number of items from the cart
 *
 * @param purchaseIds The ids of the item to remove
 * @returns {Promise<DiscountedSubscriptionOrderDto>} The updated cart
 */
export async function removeItemFromCart(purchaseIds: string[]): Promise<void> {
  const items = store
    .getState()
    .shoppingCart.purchases.filter(({ purchaseId }): boolean => purchaseIds.includes(purchaseId));

  store.dispatch(createBackup());
  store.dispatch(removeItems(purchaseIds));
  return updateCart()
    .then((res): void => {
      items.forEach(({ purchaseId }): void => {
        store.dispatch(deleteCardholders(purchaseId));
      });
      return res;
    })
}

/**
 * Adds a discount code to the cart
 *
 * @param code The code of the discount to add
 * @returns {Promise<DiscountedSubscriptionOrderDto>} The updated cart
 */
export async function addDiscountCode(code: string): Promise<void> {
  store.dispatch(createBackup());
  store.dispatch(addDiscount({ code, times: 0 }));
  return updateCart();
}

/**
 * Removes a discount code from the cart
 *
 * @param code The code of the discount to remove
 * @returns {Promise<DiscountedSubscriptionOrderDto>} The updated cart
 */
export async function removeDiscountCode(code: string): Promise<void> {
  store.dispatch(createBackup());
  store.dispatch(removeDiscount(code));
  return updateCart();
}

/**
 * Sets the quantity of a specific item in the cart
 * 
 * @param item item to set the quantity of
 * @param quantity quantity to set
 * @returns {Promise<DiscountedSubscriptionOrderDto>} The updated cart
 */
export async function setItemWithQuantity(item: SubscriptionPurchaseDto, quantity: number): Promise<void> {
  const items = store
    .getState()
    .shoppingCart.purchases.filter((p): boolean =>
      _.isEqual(_.omit(p, 'purchaseId'), _.omit(item, 'purchaseId')),
    );
  if (quantity > items.length) {
    const itemsToAdd = Array.from(
      { length: quantity - items.length },
      (): SubscriptionPurchaseDto => ({
        ...item,
        purchaseId: v4(),
      }),
    );
    return addItemsToCart(itemsToAdd);
  }
  if (quantity < items.length) {
    const itemsToRemove = items.slice(quantity);
    return removeItemFromCart(itemsToRemove.map(({ purchaseId }): string => purchaseId));
  }
  store.dispatch(createBackup());
  return updateCart();
}

/**
 * Sends the current cart to the backend to update it
 * 
 * @returns {Promise<void>} Promise that resolves when the cart is updated
 */
async function setDiscountedSubscriptionOrderDto(): Promise<void> {
  const { purchases, discounts } = store.getState().shoppingCart;
  return httpService
    .post<DiscountedSubscriptionOrderDto, DiscountableSubscriptionOrderDto>(
      '/locations/:locationId/subscription-shops/:subscriptionShopId/cart',
      {
        purchases,
        discountCodes: discounts.map(({ code }): string => code),
      },
    )
    .then(({ data }): void => {
      store.dispatch(setCart(data));
    })
    .catch((error): Promise<never> => {
      if(error.message === 'Network Error') {
        return Promise.reject(error);
      }
      if (error.response.status === StatusCodes.BAD_REQUEST || error.response.status === StatusCodes.NOT_FOUND) {
        store.dispatch(restoreCart());
        return Promise.reject(new Error('SOMETHING_BIG_CHANGED'));
      } if (error.response.status === StatusCodes.CONFLICT) {
        store.dispatch(setCart(error.response.data));
        store.dispatch(deleteBackup());
        return Promise.reject(new Error('PRICES_CHANGED'));
      }
      store.dispatch(restoreCart());
      return Promise.reject(new Error('UNKNOWN_ERROR'));
    })
}

/**
 * Sends the current cart to the backend to update it
 *
 * @returns {Promise<DiscountedSubscriptionOrderDto>} The updated cart
 */
export async function updateCart(inRepair? : boolean): Promise<void> {
  return setDiscountedSubscriptionOrderDto()
    .catch((error): Promise<never> => {
      if (error.message === 'Network Error') {
        return Promise.reject(error);
      } if (inRepair) {
        const lng = store.getState().application.language;
        localStorage.clear();
        store.dispatch(setLanguage(lng));
        return Promise.reject(new Error("REPAIR_FAILED"));
      } if(error.message === 'SOMETHING_BIG_CHANGED') {
        repairShop();
        return Promise.reject(error);
      } if(error.message === 'PRICES_CHANGED') {
        subscriptionShopService.getSubscriptionTypes();
        return Promise.reject(error);
      }
      repairShop();
      return Promise.reject(error);
    });
  }

  /**
   * Repairs the shop by fetching the subscription types and updating the cart
   * 
   * @returns {Promise<void>} The updated cart
   */
  async function repairShop(): Promise<void> {
    const updatedSubscriptions = await subscriptionShopService.getSubscriptionTypes();
    const repairedPurchases = store.getState().shoppingCart.purchases
      .map((purchase): SubscriptionPurchaseDto | null => {
        const subscriptionType = updatedSubscriptions.find((st): boolean => st.id === purchase.subscriptionTypeId);
        return subscriptionType ? {
          ...purchase,
          price: subscriptionType.price,
          discountedPrice: undefined,
        } : null;
      })
      .filter(notFalsy);
      store.dispatch(setPurchases(repairedPurchases));
      store.dispatch(createBackup());
      return updateCart(true).catch((error): void => {
        Promise.reject(error);
      });
  }

  /**
   * Clears the cart state
   */
  export function clearCartState(): void {
    store.dispatch(clearCart())
  }
