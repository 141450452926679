import React from 'react';
import { Navigate } from 'react-router-dom';
import useQuery from '../hooks/useQuery';

type ExtendedNavigateProps = {
  to: string;
} & React.ComponentProps<typeof Navigate>;

export default function ExtendedNavigate({ to, ...props }: ExtendedNavigateProps): JSX.Element {
  const query = useQuery();

  const params: string = Object.keys(query)
    .map((key): string => `${key}=${query[key as keyof typeof query]}`)
    .join('&');

  return <Navigate to={`${to}?${params}`} {...props} />;
}