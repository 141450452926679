import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { API_URL } from '../constants';

type CustomAxiosRequestConfig<T> = Omit<AxiosRequestConfig, 'params'> & {params?: T};
type CustomInstancePromises = {
  get<T = any, U = any>(url: string, config?: CustomAxiosRequestConfig<U>): Promise<AxiosResponse<T>>;
  post<T = any, D = any, U = any>(url: string, data?: D, config?: CustomAxiosRequestConfig<U>): Promise<AxiosResponse<T>>;
  put<T = any, D = any, U = any>(url: string, data?: D, config?: CustomAxiosRequestConfig<U>): Promise<AxiosResponse<T>>;
  patch<T = any, D = any, U = any>(url: string, data?: D, config?: CustomAxiosRequestConfig<U>): Promise<AxiosResponse<T>>;
  delete<T, U>(url: string, config?: CustomAxiosRequestConfig<U>): Promise<AxiosResponse<T>>;
};

type AxiosInstanceType = CustomInstancePromises & Omit<AxiosInstance, keyof CustomInstancePromises>;

const httpService = axios.create({
  baseURL: `${API_URL}/api`,
}) as AxiosInstanceType;

httpService.interceptors.request.use(
  (request: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    const { url } = request;
    if (!url?.includes(':locationId') && !url?.includes(':subscriptionShopId')) return request;
    const params = new URLSearchParams(window.location.search);

    const subscriptionShopId = params.get('sId') ?? params.get('shopId');
    const locationId = params.get('lId');
    if (!subscriptionShopId || !locationId) throw new Error('MISSING_QUERY_PARAMS');

    request.url = request.url?.replaceAll(':locationId', locationId);
    request.url = request.url?.replaceAll(':subscriptionShopId', subscriptionShopId);

    return request;
  },
);

export default httpService;
