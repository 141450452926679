import { clearUserDetails, setUserDetails } from '../redux/slices/userDetailsSlice';
import { store } from '../redux/store';
import { UserDetailsType } from '../types/userDetails';

export function saveUserDetails(details: UserDetailsType): void {
  store.dispatch(setUserDetails(details));
}
export function clearUserDetailsFromStore(): void {
  store.dispatch(clearUserDetails());
}

export function getUserDetailsType(): UserDetailsType {
  const { salutation, firstName, lastName, birthdate, email, zipCode, city } =
    store.getState().userDetails.userDetails ?? {};
  if (!salutation || !firstName || !lastName || birthdate === null || !email || !zipCode || !city)
    throw new Error('USER_DETAILS_MISSING');
  return {
    salutation,
    firstName,
    lastName,
    birthdate,
    email,
    zipCode,
    city,
  };
}
