import React, { ComponentProps } from 'react';
import Select from 'react-select';

export interface DropdownOption<T> {
  value: T;
  label: string;
}

type DropdownInputProps<T, U extends DropdownOption<T>> = {
  options: U[];
  setValue: (value: T | undefined) => void;
  value?: T | undefined;
} & Omit<ComponentProps<typeof Select<U>>, 'value' | 'setValue' | 'options'>;

export default function DropdownInput<T, U extends DropdownOption<T>>({
  options,
  value,
  setValue,
  styles: stylesProp,
  ...props
}: DropdownInputProps<T, U>): JSX.Element {
  const { control, option, indicatorSeparator, dropdownIndicator, ...styles } = stylesProp ?? {};
  return (
    <Select
      isSearchable={false}
      styles={{
        control: (baseStyles, state): any => ({
          ...baseStyles,
          borderRadius: '20px',
          borderWidth: '1px',
          boxShadow: 'none',
          height: '60px',
          ...control?.(baseStyles, state),
        }),
        option: (baseStyles, state): any => {
          const { isSelected } = state;
          return {
            ...baseStyles,
            backgroundColor: isSelected ? 'rgb(209 213 219)' : 'white',
            color: 'black',
            '&:hover': {
              backgroundColor: isSelected ? 'rgb(209 213 219)' : 'rgb(229 231 235)',
            },
            ...option?.(baseStyles, state),
          };
        },
        indicatorSeparator: (): any => ({
          display: 'none',
          ...indicatorSeparator,
        }),
        dropdownIndicator: (baseStyles, state): any => ({
          ...baseStyles,
          color: 'black',
          ...dropdownIndicator?.(baseStyles, state),
        }),
        ...styles,
      }}
      options={options}
      value={options.find((o): boolean => o.value === value)}
      onChange={(o): void => setValue(o?.value)}
      {...props}
    />
  );
}
