import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserDetailsState } from '../../types/redux';
import { UserDetailsInputType } from '../../types/userDetails';

const initialState: UserDetailsState = { userDetails: undefined };

const userDetailsSlice = createSlice({
  name: 'userDetails',
  initialState,
  reducers: {
    setUserDetails(state, action): void {
      state.userDetails = action.payload;
    },
    updateField(
      state,
      action: PayloadAction<
        | { field: keyof Omit<UserDetailsInputType, 'birthdate'>; value: string }
        | { field: 'birthdate'; value: Date | null | undefined }
      >,
    ): void {
      if (!state.userDetails) state.userDetails = {};
      const { field, value } = action.payload;
      if (field === 'birthdate') {
        state.userDetails.birthdate = value as Date;
      } else {
        state.userDetails[field] = value as string;
      }
    },
    clearUserDetails(state): void {
      state.userDetails = undefined;
    },
  },
});

const userDetailsReducer = userDetailsSlice.reducer;

export const { setUserDetails, clearUserDetails, updateField } = userDetailsSlice.actions;
export default userDetailsReducer;
