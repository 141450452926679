import React from 'react';

interface DialogBoxProps {
    isOpen: boolean;
    onClose: (result: boolean) => void;
    children?: React.ReactNode;
    titleText?: string;
    cancelText?: string;
    confirmText?: string;
}

export default function DialogBox({ isOpen, onClose, children, titleText, cancelText, confirmText }: DialogBoxProps): JSX.Element | null {
    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-lg font-bold mb-4">{titleText ?? ''}</h2>
          {children}
          <div className="mt-6 flex justify-end space-x-4">
            <button
              className="px-4 py-2 bg-gray-200 text-gray-800 rounded-full hover:bg-gray-100"
              type='button'
              onClick={(): void => onClose(false)}
            >
              {cancelText ?? 'Cancel'}
            </button>
            <button
              className="px-4 py-2 bg-sb-pink text-white rounded-full hover:bg-sb-light-pink hover:text-gray-800"
              type='button'
              onClick={(): void => onClose(true)}
            >
              {confirmText ?? 'OK'}
            </button>
          </div>
        </div>
      </div>
    );
  }