import React, { ComponentProps } from 'react';
import TextInput from './TextInput';
import Labeled from '../misc/Labeled';

type LabeledTextInputProps = {
  label?: string;
  mandatory?: boolean;
  errorMessage?: string;
} & ComponentProps<typeof TextInput>;

export default function LabeledTextInput({
  label = '',
  mandatory,
  placeholder,
  autoComplete,
  name,
  errorMessage,
  ...props
}: LabeledTextInputProps): JSX.Element {
  return (
    <Labeled label={`${label}:${mandatory ? '*' : ''}`} className="flex flex-col gap-1">
      <TextInput
        {...props}
        placeholder={placeholder ?? label}
        autoComplete={autoComplete}
        name={name ?? autoComplete}
      />
      <label className="text-sb-pink text-[15px] leading-[21px] ml-2.5">{errorMessage}</label>
    </Labeled>
  );
}
